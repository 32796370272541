// Build information, automatically generated by `ng-info`
const build = {
    version: "21.6.0",
    timestamp: "Fri Jan 31 2025 00:36:43 GMT+0100 (Central European Standard Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "543218",
        fullHash: "543218b62d071ea8b29fdeeaaa88ee6f1a6bbdc4"
    }
};

export default build;